export const VARIANT_TREE_APPLY_RULES = '/variant-tree/apply-rules'
export const VARIANT_TREE_APPLY_RULES_ASYNC = '/variant-tree/apply-rules/v2'
export const VARIANT_TREE_TABLE_AUTO_SWAP_COLUMNS = '/variant-tree/:correlationId/table/auto-swap-columns'
export const VARIANT_TREE_TABLE_CONTENT = '/variant-tree/:correlationId/table/content'
export const VARIANT_TREE_TABLE_EXPORT = '/variant-tree/:correlationId/table/export'
export const VARIANT_TREE_TABLE_HEADER = '/variant-tree/:correlationId/table/header'
export const VARIANT_TREE_TABLE_SWAP_COLUMNS = '/variant-tree/:correlationId/table/swap-columns'
export const VARIANT_TREE_CALCULATION_RESULTS = '/variant-tree/calculation-results'
export const VARIANT_TREE_RESULTS_FILE = '/variant-tree/:correlationId/table/export/url/default'
export const VARIANT_TREE_COMBINATION = '/variant-tree/:correlationId'
export const VARIANT_TREE_SAVE_CALCULATION = '/variant-tree/:correlationId/table/save'
