<template>
  <mi-header class="mi-app-header">
    <div class="column q-gutter-xs">
      <!-- Application name -->
      <div class="mi-app-header__app-name col text-body3"> {{ appName }} </div>
      <div class="col flex items-center">
        <!-- Application feature title -->
        <span class="text-uppercase text-weight-bold text-family-condensed"> {{ currentAppFeatures.title }} </span>

        <!-- Feature children routes -->
        <template v-if="currentFeatureSubRoutes.length">
          <span class="mi-app-header__breadcrumbs-separator"> / </span>
          <mi-select
            v-model="activeFeatureSubRoute"
            :menu-offset="[0, 7]"
            :options="currentFeatureSubRoutes"
            class="mi-app-header__feature-children-select text-weight-bold text-family-condensed"
            option-label="title"
            option-value="name"
            borderless
          >
            <template #option="{ itemProps, opt, selected }">
              <mi-list-item
                v-bind="itemProps"
                :to="{ name: opt.name }"
                :class="{ 'text-accent': selected }"
                class="text-weight-regular"
              >
                <mi-list-item-section> {{ opt.title }} </mi-list-item-section>
              </mi-list-item>
            </template>
          </mi-select>
        </template>
      </div>
    </div>

    <div class="mi-app-header__badge">
      <q-badge
        v-if="isDemoEnvironment"
        class="mi-app-header__badge--message text-uppercase q-mr-md q-px-md"
        color="accent"
        rounded
      >
        Demo environment
      </q-badge>
      <q-badge
        v-else-if="hasSubstringDashInt"
        class="mi-app-header__badge--message text-uppercase q-mr-md q-px-md"
        color="accent"
        rounded
      >
        Integration environment
      </q-badge>
    </div>

    <div class="mi-app-header__actions">
      <div
        class="mi-app-header__actions--notification"
        @click="whatsNew"
      >
        <img
          v-if="newNotificationAvailable"
          src="@/assets/images/notifications/ic_notification.svg"
          alt="notifications"
        />
        <img
          v-else
          src="@/assets/images/notifications/ic_notification_empty.svg"
          alt="notifications"
        />
      </div>

      <!-- Application Menu -->
      <mi-btn class="mi-app-header__actions--supermenu-btn" fab icon-type dense>
        <img src="@/assets/images/header-menu.svg" alt="" />
        <mi-menu :offset="[6, 2]" anchor="bottom right" self="top right" auto-close>
          <mi-list bordered>
            <mi-list-item
              v-for="({ title, icon }, name) in supermenuItems"
              :key="name"
              :to="{ name }"
              class="mi-app-header__supermenu-item text-weight-regular"
              active-class="text-accent"
            >
              <mi-list-item-section class="items-center q-pr-none q-mr-md" avatar>
                <img :src="icon" alt="icon" width="30" height="30" />
              </mi-list-item-section>
              <mi-list-item-section>
                {{ title }}
              </mi-list-item-section>
            </mi-list-item>
          </mi-list>
        </mi-menu>
      </mi-btn>
    </div>

    <!-- User info -->
    <template v-if="user.username" #items-right>
      <mi-btn-dropdown
        :menu-offset="[0, 4]"
        class="user-menu-btn"
        auto-close
        :label="user.username"
        dense
        flat
        icon-type
        dropdown-icon="caret-down"
      >
        <mi-list>
          <!-- Menu options -->
          <mi-list-item
            v-for="({ title, path, icon }) in USER_MENU_OPTIONS"
            v-show="userAccess(title)"
            :key="path"
            clickable
            :to="path"
          >
            <mi-list-item-section avatar>
              <mi-icon :name="icon" size="24px"></mi-icon>
            </mi-list-item-section>
            <mi-list-item-section>
              <mi-list-item-label
                class="text-weight-regular"
              >
                {{ title }}
              </mi-list-item-label>
            </mi-list-item-section>
          </mi-list-item>
        </mi-list>
      </mi-btn-dropdown>
    </template>
  </mi-header>
  <div v-if="showWhatsNew" class="whats-new-wrapper mi-drawer-content">
    <div class="drawer-background"></div>
    <mi-drawer
      v-model="showWhatsNew"
      overlay
      bordered
      side="right"
      width="800"
    >
      <q-scroll-area class="fit">
        <whats-new @open:whats-new="whatsNew"></whats-new>
      </q-scroll-area>
    </mi-drawer>
  </div>

  <check-whats-new-modal v-if="newModalNotificationAvailable"></check-whats-new-modal>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import {
    APP_FEATURES_ROUTES,
    ACTIVE_APP_FEATURES_ROUTES,
    HOME_ROUTE, INTERNAL_FEATURES_ROUTES,
    USER_MANAGEMENT_ROUTE,
    USER_FEEDBACK_ROUTE
  } from '@/router/routeNames'
  import { canAccess } from '@/utils/accessCheck'
  import WhatsNew from '@/views/whatsNew/WhatsNew.vue'
  import { NOTIFICATION_TYPES } from '@/constants/notifications'
  import CheckWhatsNewModal from '@/views/CheckWhatsNew.vue'

  export default {
    name: 'AppHeader',
    components: { CheckWhatsNewModal, WhatsNew },
    data: () => ({
      showWhatsNew: false,
      activeFeatureSubRoute: {},
      appName: process.env.VUE_APP_NAME,
      supermenuItems: {
        [HOME_ROUTE.name]: { title: HOME_ROUTE.title, icon: HOME_ROUTE.icon },
        ...ACTIVE_APP_FEATURES_ROUTES
      },
      USER_MENU_OPTIONS: {
        userManagement: {
          title: USER_MANAGEMENT_ROUTE.title,
          path: USER_MANAGEMENT_ROUTE.path,
          icon: 'settings-multi'
        },
        userFeedback: {
          title: USER_FEEDBACK_ROUTE.title,
          path: USER_FEEDBACK_ROUTE.path,
          icon: 'user-comment-outline'
        }
      }
    }),
    computed: {
      ...mapState(['user']),
      ...mapState('notifications', ['newNotificationAvailable', 'newModalNotificationAvailable']),

      currentAppFeatures() {
        return APP_FEATURES_ROUTES[this.$route.matched[0]?.name]
          || INTERNAL_FEATURES_ROUTES[this.$route.matched[0]?.name]
          || {}
      },
      currentFeatureSubRoutes() {
        return this.currentAppFeatures.children || []
      },
      hasSubstringDashInt() {
        const regex = /-int/
        const domainURL = process.env.VUE_APP_DOMAIN_URL
        return regex.test(domainURL)
      },
      isDemoEnvironment() {
        return process.env.BASE_URL === process.env.VUE_APP_DEMO_ENV_PREFIX
      }
    },
    mounted() {
      this.$watch(
        () => this.$route.name,
        () => this.updateHeader(),
        { immediate: true }
      )
    },
    methods: {
      ...mapActions('notifications', ['fetchNotificationsState']),

      updateHeader() {
        this.updateActiveFeatureSubRoute()
        this.whatsNewChecker()
      },
      whatsNew() {
        this.showWhatsNew = !this.showWhatsNew
      },
      async whatsNewChecker() {
        await this.fetchNotifications(NOTIFICATION_TYPES.whatsNew)
        await this.fetchNotifications(NOTIFICATION_TYPES.whatsNewModal)
      },
      async fetchNotifications(type) {
        await this.fetchNotificationsState({ type, seen: false })
      },
      updateActiveFeatureSubRoute() {
        const { meta } = this.$route.matched[1] || {}

        this.activeFeatureSubRoute = meta?.appFeatureSubRoute || {}
      },
      userAccess(title) {
        return title === USER_MANAGEMENT_ROUTE.title
          ? canAccess('LIST_USERS') && canAccess('CUD_USERS')
          : true
      }
    }
  }
</script>

<style lang="scss">
  .mi-drawer-content {
    .q-drawer {
      /* stylelint-disable declaration-no-important */
      width: 41% !important;
      top: 0 !important;
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .mi-drawer-content {
      .q-drawer {
        width: 100vw !important;
      }
    }
  }

  .drawer-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(67, 65, 65, .6);
    z-index: 2998;
  }
</style>

<style lang="scss" scoped>
  .mi-app-header {
    &__app-name {
      color: $mi-anthracite-200;
    }

    &__breadcrumbs-separator {
      margin: 0 .75rem 2px;
    }

    &__feature-children-select.mi-field.q-field ::v-deep(.q-field__control) {
      padding: 0;
      height: 22px;
      min-height: 22px;

      .q-field__native,
      .q-field__marginal {
        color: $mi-anthracite-600;
        padding-top: 0;
        padding-bottom: 0;
        height: inherit;
        min-height: inherit;
      }
    }

    &__badge {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      margin-left: 14px;

      &--message {
        font-size: $mi-field-font-size;
        padding: .75rem;
        font-family: $mi-typography-font-family-condensed;
        font-weight: 600;
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      &--notification {
        width: 24px;
        height: 24px;
        margin-right: 16px;
        padding: 3px;
      }

      &--notification:hover {
        cursor: pointer;
      }

      &--supermenu-btn {
        margin-bottom: -5px;
        margin-right: -3px;
      }

      &--supermenu-item ::v-deep(.q-item__section--avatar) {
        width: 24px;
        height: 24px;
        margin-right: .75rem;
        border-radius: 10px;
      }
    }

    &__username {
      max-width: 240px;
      margin-bottom: -2px;
    }
  }

  .user-menu-btn ::v-deep(.q-btn__content) {
    flex-direction: row-reverse;
  }

  .user-menu-btn ::v-deep(.q-btn-dropdown__arrow-container) {
    margin-left: 0;
    margin-right: 8px;
  }

  .whats-new-wrapper {
    z-index: 9999;
  }
</style>
