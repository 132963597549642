import { umHttpClient } from '@/api/instances'
import { USER_DELETE, USER_ROLE, USERS, USERS_ROLES } from './endpoints'

// DELETE: User
export const deleteUser = async (userId = '') => umHttpClient({
  url: USER_DELETE.replace(':userId', userId),
  method: 'DELETE'
})

// GET: Users
export const getUsers = async () => umHttpClient({
  url: USERS,
  method: 'GET'
})

// GET: User Role
export const getUserRole = async (userId = '') => umHttpClient({
  url: USER_ROLE.replace(':userId', userId),
  method: 'GET'
})

// GET: Users Roles
export const getUsersRoles = async () => umHttpClient({
  url: USERS_ROLES,
  method: 'GET'
})

// POST: Edit User Role
export const editUserRole = async ({ id = '', role = {} } = {}) => umHttpClient({
  url: USERS,
  method: 'POST',
  data: {
    id,
    role
  }
})

// PUT: Save User
export const saveUser = async ({ username = '', role = {} } = {}) => umHttpClient({
  url: USERS,
  method: 'PUT',
  data: {
    username,
    role
  }
})
