export const CURRENT_SELECTION_SAVE_THROTTLE_INTERVAL = 3000
export const RECENT_SEARCH_ITEMS_MAX_AMOUNT = 5
export const SEARCH_STRING_MIN_LENGTH = 3

export const SEARCH_ELEMENT_TYPES = Object.freeze({
  STRUCTURE: 'structure'
})

export const SEARCH_RESULT_TYPES = Object.freeze({
  OPTIONS: 'options',
  CHOICES: 'choices',
  COMPONENTS: 'components',
  COMPONENT_VARIANTS: 'componentVariants',
  RULES: 'rules',
  SOLUTION_ELEMENTS: 'solutionElements'
})

export const ELEMENT_COLORS = Object.freeze({
  YELLOW: 'yellow',
  GREEN: 'green'
})

export const ELEMENT_STATES = Object.freeze({
  IN_PREVIEW: 'inPreview',
  SELECTED: 'selected'
})

export const RESULTS_FILTER = Object.freeze({
  SHOW_CODE_ID: 'showCodeId',
  SHOW_NAME: 'showName',
  SPLIT_CODE_ID_AND_NAME: 'splitCodeIdAndName'
})

export const COMBINATIONS_STATES = Object.freeze({
  LOADING: 'Loading combinations. Please wait ...',
  REARRANGING: 'Rearranging... Please Wait'
})
