import { vmHttpClient } from '@/api/instances'
import {
  VARIANT_TREE_APPLY_RULES_ASYNC,
  VARIANT_TREE_TABLE_CONTENT,
  VARIANT_TREE_TABLE_EXPORT,
  VARIANT_TREE_TABLE_HEADER,
  VARIANT_TREE_TABLE_SWAP_COLUMNS,
  VARIANT_TREE_COMBINATION,
  VARIANT_TREE_SAVE_CALCULATION,
  VARIANT_TREE_CALCULATION_RESULTS,
  VARIANT_TREE_RESULTS_FILE
} from './endpoints'

// GET: Export variant tree table
export const exportVariantTreeTable = async ({
  aliases = {},
  filters = {},
  sorters = {},
  showCodeId = false,
  showName = false,
  showAmbiguous = false,
  splitCodeIdAndName = false,
  correlationId = ''
} = {}) => vmHttpClient({
  url: VARIANT_TREE_TABLE_EXPORT.replace(':correlationId', correlationId),
  method: 'GET',
  responseType: 'blob',
  params: new URLSearchParams({
    ...aliases,
    ...filters,
    ...sorters,
    showCodeId,
    splitCodeIdAndName,
    showName,
    showAmbiguous
  })
})

// GET: Variant tree table header
export const getVariantTreeTableHeader = async ({ aliases = {}, correlationId = '' } = {}) => vmHttpClient({
  url: VARIANT_TREE_TABLE_HEADER.replace(':correlationId', correlationId),
  method: 'GET',
  params: new URLSearchParams(aliases)
})

// GET: Variant tree table content
export const getVariantTreeTableContent = async ({
  aliases = {},
  size = 250,
  page = 1,
  showCodeId = false,
  showName = false,
  showAmbiguous = false,
  onlyIncomplete = false,
  correlationId = ''
} = {}) => vmHttpClient({
  url: VARIANT_TREE_TABLE_CONTENT.replace(':correlationId', correlationId),
  method: 'GET',
  params: new URLSearchParams({
    ...aliases,
    size,
    page,
    showCodeId,
    showName,
    showAmbiguous,
    onlyIncomplete
  })
})

// POST: Apply variant tree rules - Async Version
export const applyVariantTreeRulesAsync = async ({
  options = [],
  components = [],
  ruleIds = [],
  fromPeriod = '',
  toPeriod = '',
  pmType = '',
  pmIdentifier = '',
  name = '',
  showLater = false
} = {}) => vmHttpClient({
  url: VARIANT_TREE_APPLY_RULES_ASYNC,
  method: 'POST',
  data: {
    options,
    components,
    ruleIds,
    fromPeriod,
    toPeriod,
    pmType,
    pmIdentifier,
    name
  },
  params: new URLSearchParams({ showLater })
})

// GET: Apply variant tree rules - Async Version
export const getAppliedVariantTreeRules = async (correlationId = '') => vmHttpClient({
  url: `${ VARIANT_TREE_APPLY_RULES_ASYNC }/${ correlationId }`,
  method: 'GET'
})

// GET: Apply variant tree calculation results
export const getAppliedVariantTreeCalculationResults = async (queryParams = '') => vmHttpClient({
  url: VARIANT_TREE_CALCULATION_RESULTS,
  method: 'GET',
  params: new URLSearchParams(queryParams)
})

// GET: Apply variant tree calculation file based on the correlationId
export const getAppliedVariantTreeCalculationFile = async (correlationId = '', queryParams = '') => vmHttpClient({
  url: VARIANT_TREE_RESULTS_FILE.replace(':correlationId', correlationId),
  method: 'GET',
  params: new URLSearchParams(queryParams)
})

// POST: Swap variant tree table columns
export const swapVariantTreeTableColumns = async ({
  header = [],
  showCodeId = false,
  showName = false,
  correlationId = ''
} = {}) => vmHttpClient({
  url: VARIANT_TREE_TABLE_SWAP_COLUMNS.replace(':correlationId', correlationId),
  method: 'POST',
  data: {
    header,
    showCodeId,
    showName
  }
})

export const getVariantTreeCombination = async (correlationId = '') => vmHttpClient({
  url: VARIANT_TREE_COMBINATION.replace(':correlationId', correlationId),
  method: 'GET'
})

export const deleteVariantTreeCombination = async (correlationId = '') => vmHttpClient({
  url: VARIANT_TREE_COMBINATION.replace(':correlationId', correlationId),
  method: 'DELETE'
})

export const updateVariantTreeCombinationName = async ({ correlationId = '', name = '' }) => vmHttpClient({
  url: VARIANT_TREE_COMBINATION.replace(':correlationId', correlationId),
  method: 'PATCH',
  data: {
    name
  }
})

export const saveVariantTreeCombination = async ({ correlationId = '', name = '' }) => vmHttpClient({
  url: VARIANT_TREE_SAVE_CALCULATION.replace(':correlationId', correlationId),
  method: 'POST',
  data: {
    name
  }
})
