export const VALIDATIONS_EMPTY_STATE_TEXT = 'Create a new OPTION validation'
export const VALIDATIONS_EMPTY_STATE_TEXT_BOM = 'Create a new BOM validation'
export const VALIDATIONS_EMPTY_STATE_TEXT_GENERAL = 'No validation created for this export'

export const VALIDATIONS_EMPTY_STATE_OPTIONS = Object.freeze({
  BOM: [{
    key: 'BOM',
    label: 'BOM Validation'
  }],
  TEST_CASES: [{
    key: 'TEST_CASES',
    label: 'Test Cases'
  }]
})

export const VALIDATIONS_OPTIONS = Object.freeze({
  BOM: { label: 'BOM', key: 'BOM' },
  TEST_CASES: { label: 'Test case', key: 'TEST_CASES' },
  UNUSABLE_CHOICES: { label: 'Unusable choice', key: 'UNUSABLE_CHOICES' }
})

export const USER_EXPORT_FILTER = Object.freeze({
  NONE: { key: '', value: '' },
  USER: { key: 'creator', value: '' },
  TYPE: { key: 'containsBOM', value: '' }
})

export const USER_EXPORT_FILTERS = Object.freeze({
  CREATOR: { key: 'creator', value: 'creator' },
  BOM: { key: 'bom', value: 'containsBOM' },
  BASIC: { key: 'basic', value: 'containsBOM' }
})
