import formatDate from '@/utils/formatDate'

export const NEW_SCHEDULE_BTN_TEXT = 'New schedule'
export const USE_ELEMENTS_PROVIDER = 'useElements'
export const COLLECTION_NAME_PROVIDER = 'collectionName'
export const USE_RECENT_SEARCH_PROVIDER = 'useRecentSearch'
export const EXPORT_SETTINGS_PROVIDER = 'exportSettingsProvider'

export const SCHEDULE_DETAILS_TABS = Object.freeze({
  SCHEDULE_SETUP: { label: 'Schedule Setup', key: 'SCHEDULE_SETUP' },
  RESULTS: { label: 'Results', key: 'RESULTS' }
})

export const SCHEDULE_COLLECTION_OPTIONS = Object.freeze({
  EDIT: 'edit',
  SEND_TO_SEARCH: 'sendToSearch'
})

export const SCHEDULE_COLLECTIONS_TABLE_COLUMNS = [
  {
    name: 'name',
    label: 'Table Name',
    align: 'left',
    field: 'name',
    style: 'min-width:200px; max-width: 360px; word-break: break-word',
    showSortElement: false,
    sortDirection: 'ASC',
    sortable: true
  },
  {
    name: 'options',
    label: 'Options',
    align: 'left',
    field: 'options',
    style: 'min-width:160px;',
    sortable: false
  },
  {
    name: 'components',
    label: 'Components',
    align: 'left',
    field: 'components',
    style: 'min-width: 160px;',
    sortable: false
  },
  {
    name: 'lastEdited',
    label: 'Last Edited',
    align: 'left',
    field: 'lastEdited',
    showSortElement: true,
    sortDirection: 'DESC',
    style: 'min-width: 160px;',
    sortable: true,
    format: lastEdited => formatDate(lastEdited)
  },
  {
    name: 'actions',
    label: '',
    align: 'left',
    field: 'actions'
  }
]

export const COLLECTIONS_RESULTS_TABLE_COLUMNS = [
  {
    name: 'collectionName',
    label: 'Table Name',
    align: 'left',
    field: 'collectionName'
  },
  {
    name: 'productModelName',
    label: 'Product Model',
    align: 'left',
    field: 'productModelName'
  },
  {
    name: 'status',
    label: 'Status',
    align: 'left',
    field: 'status'
  },
  {
    name: 'executionStartDate',
    label: 'Date (start - end)',
    align: 'left',
    field: ({ executionStartDate, executionEndDate } = {}) => ({ executionStartDate, executionEndDate }),
    format: ({ executionStartDate, executionEndDate } = {}) => `${
      formatDate(executionStartDate) } - ${ executionEndDate ? formatDate(executionEndDate) : ''
    }`,
    style: 'width: 30%'
  },
  {
    name: 'export',
    label: '',
    align: 'left',
    field: ({ status, correlationId, collectionName } = {}) => ({ status, correlationId, collectionName })
  }
]

export const SCHEDULE_TYPES = ['options', 'choices', 'components', 'componentVariants']

export const SCHEDULES_RESULT_TYPES = Object.freeze({
  OPTIONS: {
    label: 'Option(s)',
    key: 'options',
    color: 'YELLOW',
    icon: 'options'
  },
  CHOICES: {
    label: 'Choice(s)',
    key: 'choices',
    color: 'YELLOW',
    icon: 'choices',
    totalSize: 0
  },
  COMPONENTS: {
    label: 'Component(s)',
    key: 'components',
    color: 'GREEN',
    icon: 'components',
    totalSize: 0
  },
  COMPONENT_VARIANTS: {
    label: 'Component Variant(s)',
    key: 'componentVariants',
    color: 'GREEN',
    icon: 'component-variants'
  }
})

export const SCHEDULE_ELEMENT_COLORS = Object.freeze({
  options: 'yellow',
  choices: 'yellow',
  components: 'green',
  componentVariants: 'green'
})

export const COLLECTIONS_RESULT_STATUS = Object.freeze({
  FAILED: { key: 'FAILED', title: 'failed', color: 'error' },
  IN_PROGRESS: { key: 'IN_PROGRESS', title: 'in progress', color: 'info' },
  SUCCESS: { key: 'SUCCESS', title: 'completed', color: 'success' }
})

export const EXPORT_SETTINGS_DEFAULT = {
  showCodeId: { key: 'showCodeId', title: 'Display ID', state: true },
  showName: { key: 'showName', title: 'Display Name', state: true },
  splitCodeIdAndName: { key: 'splitCodeIdAndName', title: 'Split name from ID cells', state: false }
}

export const COLLECTIONS_RESULTS_MOCKS = [
  {
    id: 'xxx',
    name: '00_00_KVMMA_Breire_VA_Aschse_Reifen_Felge_Forecast_LL',
    productModel: 'Series',
    createdDate: '2024-06-24T19:24:45',
    calculationCompleteDate: '',
    status: 'IN_PROGRESS'
  },
  {
    id: 'xxx',
    name: '00_00_KVMMA_Breire_VA_Aschse_Reifen_Felge_Forecast_LL',
    productModel: 'Series',
    createdDate: '2024-06-17T15:03:45',
    calculationCompleteDate: '2024-06-20T18:39:45',
    status: 'SUCCESS'
  },
  {
    id: 'xxx',
    name: '00_00_KVMMA_Breire_VA_Aschse_Reifen_Felge_Forecast_LL',
    productModel: 'Series',
    createdDate: '2024-06-10T20:24:45',
    calculationCompleteDate: '2024-06-19T09:46:45',
    status: 'FAILED'
  }
]
