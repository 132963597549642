export const PM_TYPE = {
  PRODUCT_MODEL: 'PRODUCT_MODEL',
  USER_EXPORTS: 'USER_EXPORTS'
}

export const EXTRA_PM_TYPES = [
  { type: 'basic', businessName: 'Basic Exports', child: [] },
  { type: 'bom', businessName: 'Bom Exports', child: [] }
]

export const PM_CHANGE_TABS = {
  ELEMENTS_TO_BE_ADDED: {
    key: 'elementsToBeAdded',
    label: 'Added elements',
    modificationType: 'ADDED'
  },
  ELEMENTS_TO_BE_REMOVED: {
    key: 'elementsToBeRemoved',
    label: 'Removed elements',
    modificationType: 'REMOVED'
  }
}
