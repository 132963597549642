import { whatsNewHttpClient } from '@/api/instances'
import { WHATS_NEW_CONTENT, WHATS_NEW_DATA } from './endpoints'

export const getWhatsNewContent = async () => whatsNewHttpClient({
  url: WHATS_NEW_CONTENT,
  method: 'GET'
})

export const getWhatsNewData = async ({
  application = 'PI_PORTAL',
  timestampStart = new Date(new Date().setDate(new Date().getDate() - 180)).toISOString()
} = {}) => whatsNewHttpClient({
  url: WHATS_NEW_DATA,
  method: 'GET',
  params: {
    application,
    timestampStart
  }
})

export const getModalWhatsNewData = async ({
  application = 'PI_PORTAL',
  timestampStart,
  highlightFlag = true
} = {}) => whatsNewHttpClient({
  url: WHATS_NEW_DATA,
  method: 'GET',
  params: {
    application,
    timestampStart,
    highlightFlag
  }
})

export const addWhatsNewData = async (
  data,
  {
    application = 'PI_PORTAL',
    versionTimestamp = new Date().toISOString(),
    highlightFlag = false
  } = {}
) => whatsNewHttpClient({
  url: WHATS_NEW_DATA,
  method: 'POST',
  params: {
    application,
    versionTimestamp,
    highlightFlag
  },
  data
})

export const deleteWhatsNewData = async (application, versionTimestamp) => whatsNewHttpClient({
  url: WHATS_NEW_DATA,
  method: 'DELETE',
  params: {
    application,
    versionTimestamp
  }
})
