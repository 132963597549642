import { pmvHttpClient } from '@/api/instances'
import {
  TEST_CASE_VALIDATION,
  TEST_CASE_VALIDATION_EXPORT,
  TEST_CASE_VALIDATIONS,
  TEST_CASE_EXPLANATION,
  TEST_CASES,
  TEST_CASES_COMPARISON,
  BOM_VALIDATION_EXPORT,
  UNUSABLE_CHOICES,
  VALIDATION_UNUSABLE_CHOICES,
  VALIDATION_UNUSABLE_CHOICES_SUMMARY,
  UNUSABLE_CHOICES_EXPORT,
  TOGGLE_PIN_TEST_CASE,
  DELETE_TEST_CASE,
  COMPARE_NOT_USABLE_CHOICES, TEST_CASE
} from './endpoints'

// DELETE: Test Case Validation
export const deleteTestCaseValidation = async (validationId = '') => pmvHttpClient({
  url: TEST_CASE_VALIDATION.replace(':validationId', validationId),
  method: 'DELETE'
})

// Delete: Daily Test Cases
export const deleteDailyTestCase = async (testCasesFileId = '') => pmvHttpClient({
  url: DELETE_TEST_CASE.replace(':testCaseFileId', testCasesFileId),
  method: 'DELETE'
})

// POST: Create correlationId for Test Case Validation Explanation
export const requestTestCaseValidationExplanation = async ({
  validationId = '',
  validationResultId = ''
} = {}) => pmvHttpClient({
  url: TEST_CASE_EXPLANATION,
  method: 'POST',
  data: {
    validationId,
    validationResultId
  }
})

export const compareNotUsableChoices = async ({
  resultDate = '',
  compareWith = '',
  params = {}
} = {}) => pmvHttpClient({
  url: COMPARE_NOT_USABLE_CHOICES,
  method: 'POST',
  data: {
    resultDate,
    compareWith
  },
  params: new URLSearchParams(params)
})

// GET ASYNC: Test Case Validation Explanation
export const getTestCaseValidationExplanation = async (correlationId = '') => pmvHttpClient({
  url: `${ TEST_CASE_EXPLANATION }/${ correlationId }`,
  method: 'GET'
})

// GET: Test Cases
export const getTestCases = async (queryParams = '') => pmvHttpClient({
  url: TEST_CASES,
  method: 'GET',
  params: new URLSearchParams(queryParams)
})

// GET: Test Case
export const getTestCase = async (testCaseId = '') => pmvHttpClient({
  url: TEST_CASE.replace(':testCaseId', testCaseId),
  method: 'GET'
})

// GET: Test Case Validations
export const getTestCaseValidations = async (testCaseId = '') => pmvHttpClient({
  url: TEST_CASE_VALIDATIONS.replace(':testCaseId', testCaseId),
  method: 'GET'
})

// GET: Test Cases Comparison
export const getTestCasesComparison = async (params = {}) => pmvHttpClient({
  url: TEST_CASES_COMPARISON,
  method: 'GET',
  params
})

// PATCH: Test Case Toggle Pin
export const togglePinTestCase = async (testCaseId = '') => pmvHttpClient({
  url: TOGGLE_PIN_TEST_CASE.replace(':testCaseId', testCaseId),
  method: 'PATCH'
})

// GET: Test Case Validation
export const getTestCaseValidation = async (validationId = '') => pmvHttpClient({
  url: TEST_CASE_VALIDATION.replace(':validationId', validationId),
  method: 'GET'
})

// GET: Test Case Validation Export Excel
export const exportTestCaseValidation = async (validationId = '') => pmvHttpClient({
  url: TEST_CASE_VALIDATION_EXPORT.replace(':validationId', validationId),
  method: 'GET',
  responseType: 'blob'
})

// GET: Unusable Choices
export const getUnusableChoices = async (queryParams = '') => pmvHttpClient({
  url: UNUSABLE_CHOICES,
  method: 'GET',
  params: new URLSearchParams(queryParams)
})

// GET: Validation Unusable Choices'
export const getValidationUnusableChoices = async (validationId = '', queryParams = '') => pmvHttpClient({
  url: VALIDATION_UNUSABLE_CHOICES.replace(':validationId', validationId),
  method: 'GET',
  params: new URLSearchParams(queryParams)
})

// GET: Validation Unusable Choices Summary'
export const getValidationUnusableChoicesSummary = async (validationId = '') => pmvHttpClient({
  url: VALIDATION_UNUSABLE_CHOICES_SUMMARY.replace(':validationId', validationId),
  method: 'GET'
})

// GET: Unusable Choices excel
export const exportUnusable = async (id = '') => pmvHttpClient({
  url: UNUSABLE_CHOICES_EXPORT.replace(':id', id),
  method: 'GET',
  responseType: 'blob'
})

// GET: BOM Validation Export Excel
export const exportBomValidation = async (validationId = '') => pmvHttpClient({
  url: BOM_VALIDATION_EXPORT.replace(':validationId', validationId),
  method: 'GET',
  responseType: 'blob'
})

// POST: Upload Test Case
export const uploadTestCase = async ({ file = {}, filename = '' } = {}) => {
  const formData = new FormData()

  formData.append('file', file)
  formData.append('filename', filename)

  return pmvHttpClient({
    url: TEST_CASES,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// POST: Validate Test Case
export const validateTestCase = async ({
  testCaseId = '',
  initialPP = '',
  finalPP = '',
  productModelId = '',
  encodedBusinessName = ''
} = {}) => pmvHttpClient({
  url: TEST_CASE_VALIDATIONS.replace(':testCaseId', testCaseId),
  method: 'POST',
  data: {
    planningPeriodRange: {
      from: initialPP,
      to: finalPP
    },
    productModelId,
    encodedBusinessName
  }
})
