export const ASYNC_REQUEST_STATUS = Object.freeze({
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  STOPPED: 'STOPPED'
})

export const ERRORS = Object.freeze({
  NO_RECOVERY_SUGGESTIONS_AVAILABLE: 'This combination is invalid, but no recovery suggestions were found.'
})
