/**
 * "v-click-outside"
 *
 * Detects click outside an element attached.
 * Requires function to be provided as a "binding"
 */
export default {
  beforeMount(el, binding) {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = event => {
      if (!el.contains(event.target)) {
        binding.value?.(event, el)
      }
    }

    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}
