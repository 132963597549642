export const TEST_CASE_VALIDATION = '/test-cases/validations/:validationId'
export const TEST_CASE_VALIDATION_EXPLANATION = '/test-cases/validations/:testCaseId/explanation'
export const TEST_CASE_VALIDATION_EXPORT = '/test-cases/validations/:validationId/export-excel'
export const BOM_VALIDATION_EXPORT = '/component/:validationId/export-excel'
export const UNUSABLE_CHOICES_EXPORT = '/export/invalid-choices/:id'
export const TEST_CASE_VALIDATIONS = '/test-cases/:testCaseId/validations'
export const TEST_CASE_EXPLANATION = '/test-cases/explanation'
export const TEST_CASES = '/test-cases'
export const TEST_CASE = '/test-cases/:testCaseId'
export const TEST_CASES_COMPARISON = '/test-cases/validations/comparison'
export const UNUSABLE_CHOICES = '/unusable-choices'
export const VALIDATION_UNUSABLE_CHOICES = '/unusable-choices/:validationId/content'
export const VALIDATION_UNUSABLE_CHOICES_SUMMARY = 'unusable-choices/:validationId/summary'
export const TOGGLE_PIN_TEST_CASE = '/test-cases/:testCaseId/toggle-pin'
export const DELETE_TEST_CASE = '/test-cases/:testCaseFileId'
export const COMPARE_NOT_USABLE_CHOICES = '/unusable-choices/comparison'
