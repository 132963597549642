import { hasAccess } from '@/utils/accessCheck'

/**
 * "v-can"
 *
 * Checks if user has access to the element.
 * Otherwise, removes a Node from DOM tree.
 */
export default {
  mounted(el, binding, vNode) {
    if (!hasAccess([binding?.value])) {
      vNode.el?.remove()
    }
  }
}
